<template>
  <div class="ui tiny chrome modal" style="text-align:center;background:#324851;">
    <div class="ui content">
      <h2 v-if="$route.name==='HomeProse'" class="ui center aligned header whiteText">
        Voice & the Transcript Editor are currently accessible only through Desktop Chrome.
      </h2>
      <h2 v-else class="ui center aligned header whiteText">
        We love you for trying us out<i class="twa twa-heart"></i><br>Right now we only support Desktop Chrome
      </h2>
      <div style="margin-top:30px;">
        <h3 class="whiteText">Please login from Desktop Chrome Browser</h3>
      </div>
    </div>
    <div class="actions" style="text-align:center;margin-top:20px;">
      <!-- <div v-if="isMobile" class="labeled icon teal ui basic button" onclick="window.Intercom('showNewMessage')">
        Chat with us
        <i class="comment icon"></i>
      </div> -->
      <div v-if="!isMobile" class="labeled icon teal ui button" @click="goToChromeDownload">
        Download Chrome
        <i class="chrome icon"></i>
      </div>
      <div v-if="$route.name==='HomeProse'" class="labeled icon teal ui button" @click="logout">
        Logout
        <i class="left arrow icon"></i>
      </div>
      <div v-else class="labeled icon teal ui button" @click="logout">
        Go back to website
        <i class="left arrow icon"></i>
      </div>
    </div>
  </div>
</template>
<script>
require('../assets/css/twemoji-awesome.css')
export default {
  props: ['isMobile'],
  methods: {
    goToChromeDownload () {
      console.log('download chrome')
      window.open('https://www.google.com/chrome/', '_blank')
    },
    logout () {
      this.$parent.closeChromeModal()
      this.$parent.logOut()
    }
  }
}
</script>

<style lang="css" scoped>
  .whiteText {
    color: white !important;
  }

  .tealText {
    color: #5A29A3;
  }

  .ui.teal.button {
    background-color: #5A29A3 !important;
  }
  .ui.teal.basic.button {
    background-color:transparent !important;
    box-shadow:0 0 0 1px #5A29A3 inset!important;
    color:white!important;
  }
</style>
